@tailwind base;
@tailwind components;
@tailwind utilities;

/* Webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 8px;  /* Adjust the width as needed */
  background-color: rgb(31, 41, 55);  /* Same as container background */
}

::-webkit-scrollbar-thumb {
  background-color: rgb(31, 41, 55);  /* Same as container background */
  border-radius: 10px;  /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #ccc;  /* Slightly different on hover */
}

::-webkit-scrollbar-track {
  background-color: rgb(31, 41, 55);  /* Same as container background */
}

/* Firefox */
* {
  scrollbar-width: thin;  /* Minimal width */
  scrollbar-color: rgb(17, 24, 39) rgb(31, 41, 55)  ;  /* Thumb and track color */
}

/* For other browsers that support scrollbar-color */
* {
  scrollbar-color: rgb(17, 24, 39) rgb(31, 41, 55)  ;  /* Thumb and track color */
  scrollbar-width: thin;  /* Minimal width */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-gray-800
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.vt323-regular {
  font-family: "VT323", monospace;
  font-weight: 400;
  font-style: normal;
}

.text-glare  {
  font-weight: 400;
  font-style: normal;
  color: #33ff33;
  text-shadow: 0 0 10px #33ff33, 0 0 0px #33ff33, 0 0 20px #33ff33;
}

.text-glare-secondary  {
  font-weight: 400;
  font-style: normal;
  color: #ff33aa;
  text-shadow: 0 0 10px #ff33aa, 0 0 0px #ff33aa, 0 0 20px #ff33aa;
}

.font-vt{
  font-family: "VT323", monospace;
  font-style: normal;
}

.glare-text {
  padding: 0px 15px;
  font-family: 'VT323', monospace;
  font-weight: 400;
  font-style: normal;
  color: #33ff33;
  text-shadow: 0 0 10px #33ff33, 0 0 0px #33ff33, 0 0 20px #33ff33;
  font-size: 20px;
}

.glare-text-secondary {
  font-family: 'VT323', monospace;
  font-weight: 400;
  font-style: normal;
  color: #ff33aa;
  text-shadow: 0 0 10px #ff33aa, 0 0 0px #ff33aa, 0 0 20px #ff33aa;
  font-size: 20px;
}

.input-glare{
  font-family: 'VT323', monospace;
  font-weight: 400;
  font-style: normal;
  color: #ff33aa;
  font-size: 20px;
  text-shadow: 0 0 10px #ff33aa, 0 0 0px #ff33aa, 0 0 20px #ff33aa;
}


.input-glare::placeholder {
  color: rgba(255, 51, 170, 0.2); /* Change this color to your desired placeholder color */
  text-shadow: 0 0 10px #ff33aa, 0 0 0px #ff33aa, 0 0 20px #ff33aa;
}

.glare-effect {
  position: relative;
  display: inline-block;
}

.glare-effect::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(white, transparent);
  transform: translate(-50%, -50%) rotate(45deg);
  opacity: 0.1;
  pointer-events: none;
}

.cli-output-container {
  @apply max-h-max;
}

/* Optional: Apply a blur effect to simulate an old screen */
.screen-blur {
  backdrop-filter: blur(2px);
}

.material-symbols-outlined {
 font-variation-settings:
         'FILL' 0,
         'wght' 400,
         'GRAD' 0,
         'opsz' 24
}

.glare-border {
  border: 2px solid;
  border-image: linear-gradient(45deg, #33ff33, #055d05) 1;
}

.glare-background {
  background: linear-gradient(45deg, #33ff33, #055d05);
}

.glare-shadow {
  box-shadow: 0 4px 6px -1px rgba(51, 255, 51, 0.1), 0 2px 4px -1px rgba(51, 255, 51, 0.06);
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
